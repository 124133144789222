import $ from 'jquery';
const anime = require('animejs');

let wasAnimated = false;
let wasAnimatedBottom = false;

function resolveOpacity() {
    if ($(document).width() < 1300) {
        return 1.1;
    }

    if ($(document).width() >= 1300 && $(document).width() < 1499) {
        return 1.35;
    }

    if ($(document).width() >= 1500) {
        return 1.6;
    }

    return 1.5;
}

$(document).ready(() => {
    $('.lines-parts .circle').addClass('hide-item');
    $('.lines-parts .line').addClass('hide-item');

    $('.desc-bg').each(function() {
        const width = $(this).parent().width() + 75;
        const height = $(this).parent().height() + 15;
        $(this).find('img').css('width', width).css('height', height);
    });

    $(window).scroll(function() {
        const scrollTop = $(this).scrollTop();

        const $lightOverlay = $('.light-overlay');
        $lightOverlay.css({
            opacity() {
                const elementHeight = $lightOverlay.height();
                return ((elementHeight - scrollTop) / elementHeight) + resolveOpacity();
            }
        });

        if (parseFloat($lightOverlay.css('opacity')) <= 0.5 && !wasAnimated) {
            $('.lines-parts .circle').removeClass('hide-item');
            $('.lines-parts .line').removeClass('hide-item');


            wasAnimated = true;
            anime({
                targets: '.lines-parts .circle circle',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 500,
                direction: 'alternate',
                loop: false,
                delay: 300
            });

            anime({
                targets: '.line path',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 500,
                direction: 'alternate',
                loop: false,
                delay: 1000
            });

            anime({
                targets: '.main-text, .expand-desc',
                opacity: 1,
                delay: 1000
            });

            anime({
                targets: '.expand-desc',
                translateY: -10,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            });
        }

        if (parseFloat($lightOverlay.css('opacity')) <= 0.25 && !wasAnimatedBottom) {
            wasAnimatedBottom = true;
            anime({
                targets: '.shoe-params .param',
                translateY: -250,
                opacity: 1
            });
        }
    });
});

