// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
                                // or other things that require babel runtime

import Preloader from './modules/preloader';
import './modules/isMobile';
import './modules/ga';
import './modules/sections/section2';
import './modules/sections/section3';
import './modules/sections/section5';

window.addEventListener('load', () => {
    Preloader.hide();
});
