import $ from 'jquery';

$(document).ready(() => {
    $('.runner4').hover(() => {
        $('.runner4').siblings('.runners').toggleClass('move-left');
    });


    $('.group1 .main-text').hover(() => {
        $('.group1 .main-text').siblings('.description').toggleClass('main-text-hover');
    });

    $('.group2 .main-text').hover(() => {
        $('.group2 .main-text').siblings('.description').toggleClass('main-text-hover');
    });

    $('.group3 .main-text').hover(() => {
        $('.group3 .main-text').siblings('.description').toggleClass('main-text-hover');
    });

    $('.arrow-up').click(() => {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        return false;
    });
});
